import { useTranslation } from 'next-i18next'

import { Close } from '@mui/icons-material'
import type { SxProps, Theme } from '@mui/material'
import { Box, IconButton, Typography } from '@mui/material'

const styles: SxProps<Theme> = {
  position: 'fixed',
  top: 0,
  zIndex: 10,
  width: { xs: '100%', sm: '375px' },
  backgroundColor: (theme) => theme.palette.background.paper,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px 12px',
  borderBottom: (theme) => `1px solid ${theme.palette.divider}`,
  borderRight: (theme) => ({ xs: '0', sm: `1px solid ${theme.palette.divider}` }),
}

type PanelHeaderProps = {
  onCloseDrawer: () => void
}

export const PanelHeader: React.FC<PanelHeaderProps> = ({ onCloseDrawer }) => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.searchAndHitsPanel.actionsBar',
  })

  return (
    <Box component="header" sx={styles}>
      <Box width="34px" />
      <Typography variant="h6" color="text.primary">
        {t('buttons.filters')}
      </Typography>
      <IconButton
        aria-label="delete"
        size="small"
        onClick={onCloseDrawer}
        data-cy="close-filters-button"
      >
        <Close sx={{ color: 'text.secondary' }} />
      </IconButton>
    </Box>
  )
}
