import { useTranslation } from 'next-i18next'

import { Box, Button, Stack } from '@mui/material'

import { ResetAllButton } from '@/components/ResetAllButton'

const styles = {
  container: {
    position: 'fixed',
    bottom: 0,
    zIndex: 10,
    backgroundColor: 'background.default',
    width: { xs: '100%', sm: '375px' },
    padding: '12px 16px',
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: 1.5,
    margin: 'auto',
  },
}

type PanelFooterProps = {
  onShowResults: () => void
}

export const PanelFooter: React.FC<PanelFooterProps> = ({ onShowResults }) => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.buttons',
  })

  return (
    <Box sx={styles.container}>
      <Stack direction="row" sx={styles.buttons}>
        <ResetAllButton dataCy="drawer__reset-all-filters-button" />
        <Button
          variant="contained"
          size="medium"
          onClick={onShowResults}
          data-cy="drawer__show-results-button"
          sx={{ height: '40px', width: '50%' }}
        >
          {t('showResults')}
        </Button>
      </Stack>
    </Box>
  )
}
