import { useTranslation } from 'next-i18next'

import { useLanguageAndCountry } from 'src/hooks/useLanguageAndCountry'
import { inferLanguageLabelByLanguageCode } from 'src/utils/plain/inferLanguageLabelByLanguageCode'
import { useDeepCompareCallback } from 'use-deep-compare'

import { DrawerFacet } from '@/components/DrawerFacet'

export const PanelEntries = () => {
  const { t } = useTranslation('download-center', {
    keyPrefix: 'mainPage.filtersPanel.filters',
  })
  const { t: tDA } = useTranslation('dam-assets')

  const { languageCode } = useLanguageAndCountry()

  const sortItems = (items) =>
    items.sort((a, b) => {
      if (a.isRefined !== b.isRefined) {
        // when either `a` or `b` is refined but not both,
        if (a.isRefined) {
          // if `a` is refined, it comes before `b`
          return -1
        }
        // `b` is refined but not `a` => `b` comes before `a`
        return 1
      }

      // either both `a` and `b` are refined or neither => usual sorting logic
      return a.label.localeCompare(b.label)
    })

  const transformItems = (keyPrefix: string) => (items) =>
    sortItems(
      items.map((item) => ({
        ...item,
        label:
          keyPrefix === 'language'
            ? inferLanguageLabelByLanguageCode(languageCode)(item.label)
            : tDA(`${keyPrefix}.${item.label}`),
      })),
    )

  const memoizedTransformItemsDocumentTypes = useDeepCompareCallback(
    transformItems('assetCategories'),
    [],
  )
  const memoizedTransformItemsDivision = useDeepCompareCallback(transformItems('divisions'), [])
  const memoizedTransformItemsLanguage = useDeepCompareCallback(transformItems('language'), [])

  return (
    <>
      <DrawerFacet
        attribute="documentTypes"
        includedAttrsInTrackResetFilters={['documentTypes']}
        title={t('documentType')}
        transformItems={memoizedTransformItemsDocumentTypes}
      />
      <DrawerFacet
        attribute="productCategories.lvl0"
        includedAttrsInTrackResetFilters={['division']}
        title={t('productCategory')}
        withlanguageCodeSuffix
        transformItems={sortItems}
      />
      <DrawerFacet
        attribute="fieldsOfApplication"
        includedAttrsInTrackResetFilters={['division']}
        title={t('fieldOfApplication')}
        withlanguageCodeSuffix
        transformItems={sortItems}
      />
      <DrawerFacet
        attribute="areasOfApplication"
        includedAttrsInTrackResetFilters={['division']}
        title={t('areaOfApplication')}
        withlanguageCodeSuffix
        transformItems={sortItems}
      />
      <DrawerFacet
        attribute="division"
        includedAttrsInTrackResetFilters={['division']}
        title={t('division')}
        transformItems={memoizedTransformItemsDivision}
      />
      <DrawerFacet
        attribute="language"
        includedAttrsInTrackResetFilters={['language']}
        title={t('language')}
        transformItems={memoizedTransformItemsLanguage}
      />
    </>
  )
}
